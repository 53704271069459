<template>
  <div class="MainPage">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  mounted () {
  },
  methods: {
  }
}
</script>

<style lang="less" scoped>
.MainPage {
  width: 100%;
  height: 100%;
}
</style>
